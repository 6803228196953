import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout";
import Article from "../components/Article";
import Section from "../components/Section";
import PageTitle from "../components/PageTitle";
import PageHeader from "../components/PageHeader";

const PressSingle = ({ data }) => {
	const { contentfulPost } = data;
	return (
		<Layout>
			<main>
				<PageHeader>
					<PageTitle isSmall>
						<h1>{contentfulPost.title}</h1>
					</PageTitle>
				</PageHeader>
				<Section hasMaxWidth isPressPage>
					<Article content={contentfulPost} />
				</Section>
			</main>
		</Layout>
	);
};

export default PressSingle;

export function Head({ data }) {
	const { contentfulPost } = data;
	return <title>{contentfulPost.title} | Derschlag</title>;
}

export const query = graphql`
	query PostBySlug($locale: String!, $slug: String!) {
		contentfulPost(slug: { eq: $slug }, node_locale: { eq: $locale }) {
			...PostFields
		}
	}
`;
