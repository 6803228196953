import * as React from 'react';
import * as styles from './Article.module.scss';
import {getImage} from "../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {BLOCKS, INLINES} from "@contentful/rich-text-types";
import Subtitle from "./Subtitle";

const Article = ({content}) => {
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                console.log(node);
                return (
                    <a href={node.data.uri} target={'_blank'}>{children}</a>
                )
            },
        },
    };
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <img src={getImage(content.image).url} alt={getImage(content.image).url}/>
                {renderRichText(content.body, options)}
            </div>
        </div>
    )
}

export default Article;